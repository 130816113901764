import React, { useMemo, useState, useEffect, useRef } from "react";
import BtnVolver from "../../common/BtnVolver";
import Datetime from 'react-datetime';
import { Link } from "react-router-dom";
import { Modal, Form, Container, Col, Row, Card, Spinner, Button } from "react-bootstrap";
import { FaFileMedical, FaPlusCircle, FaEraser } from "react-icons/fa";
import ConsultasAPI from "../../../shared/helpers/consultasAPI";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable from "material-react-table";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import { darken } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ModalNuevoSorteo } from "./ModalNuevoSorteo"
import Swal from "sweetalert2";

const SubirExtractoTuqui = (props) => {
    const rolUser = props.rolUsuario;
    const [data, setData] = useState([]);
    const URL_PUBLICACION_EXTRACTO = window.API_ROUTES.PUBLICACIONEXTRACTO;
    const URL_SORTEO = window.API_ROUTES.SORTEO;
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const datetimeRef = useRef(null);
    const [fecha, setFecha] = useState({ fechaMuestra: null, fechaComparar: null });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [count, setCount] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openViewImage, setOpenViewImage] = useState(false);
    const [sorteoView, setSorteoView] = useState('')
    const [imgView, setimgView] = useState('')
    const [sorteo, setSorteo] = useState('');
    const [tablaSorteo, setTablaSorteo] = useState([]);
    const [showModalNuevoSorteo, setShowModalNuevoSorteo] = useState(false);
    const [tituloModal, setTituloModal] = useState("");
    const [archivoExtracto, setArchivoExtracto] = useState("");

    // Definir el estado que indica si se debe mostrar o no el modal

    const columns = useMemo(() => [
        {
            header: "id",
            accessorKey: "id",
            size: 50,
        },
        {
            header: "N° Sorteo",
            accessorKey: "numSorteo",
            size: 50,
        },
        {
            header: "Fecha de Sorteo",
            accessorKey: "fechaSorteo",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Fecha de Subida",
            accessorKey: "fechaSubida",
            size: 50,
            enableColumnFilter: false,
        },
        {
            header: "Usuario",
            accessorKey: "usuario",
            size: 210, // 140
        }
    ]);

    useEffect(() => {
        cargarArchivo();
    }, [pagination, columnFilters, sorting, fecha]);

    useEffect(() => {
        cargarSorteos();
    }, []);

    const cargarSorteos = async () => {
        const response = await ConsultasAPI.ListarObjetos(URL_SORTEO + 'listarTodos/');
        if (response.status === 200) {
            let vector = [];
            response.data.forEach(sorteo => {
                let data = {
                    numero: sorteo.numero,
                    id: sorteo.id,
                }
                vector.push(data);
            });
            setTablaSorteo(vector);
        } else {
            setTablaSorteo([]);
        }
    }

    //Trae los datos desde el back para mostrar en la tabla del front
    const cargarArchivo = async () => {
        const response = await ConsultasAPI.ListarObjetos(
            URL_PUBLICACION_EXTRACTO, pagination.pageIndex, pagination.pageSize, columnFilters, fecha.fechaComparar
        )
        if (response.status === 200) {

            setCount(response.data.count);
            let datos = [];
            response.data.results.forEach((Extracto) => {
                datos.push({
                    id: Extracto.id,
                    usuario: Extracto.creado_por,
                    numSorteo: Extracto.sorteo_detalle.numero,
                    fechaSorteo: Extracto.sorteo_detalle.fecha_sorteo,
                    fechaSubida: Extracto.fecha_creacion.split(" ")[0],
                });
            });
            setData(datos);
        } else {
            setCount(0);
            setData([]);
        }
    };

    const handleFechaChange = (momentDate) => {
        const fechaMuestra = momentDate.format('DD/MM/YYYY');
        const fechaComparar = momentDate.format('YYYY-MM-DD');
        setFecha({ fechaMuestra: fechaMuestra, fechaComparar: fechaComparar });
    };

    const handleClickClear = () => {
        setFecha({ fechaMuestra: null, fechaComparar: null });
        datetimeRef.current.setState({ inputValue: '' });
    };

    const handleCloseModal = () => {
        setSorteo('')
        datetimeRef.current.setState({ inputValue: '' });
        if (document.getElementById('archivoExtracto')) {
            document.getElementById('archivoExtracto').value = null;
        }
        setOpenModal(false);
        cargarArchivo();
    }

    const handleTablaSorteoChange = (sorteo) => {
        setSorteo(tablaSorteo.filter((x) => x.id === parseInt(sorteo))[0]);
    };

    const handleOpenModalNuevoSorteo = () => {
        setTituloModal("Nuevo");
        setShowModalNuevoSorteo(true);
    };

    const handleCloseModalNuevoSorteo = () => {
        setShowModalNuevoSorteo(false);
        cargarSorteos();
    };


    const handleArchivoPDFChange = (event) => {
        const validTypes = ['image/jpeg', 'image/jpg'];
        if (event.target.files[0]) {
            if (validTypes.includes(event.target.files[0].type)) {
                const archivo = {
                    path: event.target.files[0],
                };
                setArchivoExtracto(archivo);
            } else {
                Swal.fire(
                    'Formato incorrecto',
                    'El archivo no es de formato imagen',
                    'error'
                );
                event.target.value = null;
            }
        }
    };

    const subirExtracto = async (event) => {
        event.preventDefault()
        Swal.fire({
            title: '¿Estás seguro de subir los archivos?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#008185',
            cancelButtonColor: '#EC1B23',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                let formData = new FormData();
                formData.append('archivoExtracto', archivoExtracto.path);
                formData.append('sorteo_id', sorteo.id);
                const response = await ConsultasAPI.CrearObjetoArchivo(URL_PUBLICACION_EXTRACTO + 'crearExtracto/', formData);
                if (response.status === 200) {
                    setIsLoading(false);
                    Swal.fire(
                        'Creado con exito',
                        'Se creo exitosamente el extracto',
                        'success'
                    );
                    handleCloseModal();
                } else if (response.status === 226) {
                    setIsLoading(false);
                    Swal.fire(
                        'Extracto ya subido',
                        'Este sorteo ya tiene un extracto subido',
                        'error'
                    );
                } else {
                    setIsLoading(false);
                    Swal.fire(
                        'Ocurrio un error',
                        'Algo salio mal al intentar crear el extracto',
                        'error'
                    );
                }
            }
        }).catch(() => {
            setIsLoading(false);
            Swal.fire(
                'Ocurrio un error',
                'Algo salio mal al intentar crear el extracto',
                'error'
            );
        });
    }

    const handleViewImage = async (row) => {
        try {
            const response = await ConsultasAPI.ObtenerObjeto(URL_PUBLICACION_EXTRACTO+'obtenerImagen/',row.id)
            if(response.status === 200){
                setSorteoView(row.numSorteo)
                setimgView(response.data)
                setOpenViewImage(true);
            }else if(response.status === 203){
                Swal.fire({
                    title: 'Ocurrio un error al obtener la imagen',
                    text: 'No existe el extracto seleccionado',
                    icon: 'error',
                });
                setSorteoView('')
                setimgView('')
                setOpenViewImage(false);
            }else if(response.status === 204){
                Swal.fire({
                    title: 'Ocurrio un error al obtener la imagen',
                    text: 'No se encontro la imagen en el servidor',
                    icon: 'error',
                });
                setSorteoView('')
                setimgView('')
                setOpenViewImage(false);
            }else{
                Swal.fire({
                    title: 'Ocurrio un error al obtener la imagen',
                    text: 'Algo salio mal al intentar obtener la imagen del servidor',
                    icon: 'error',
                });
                setSorteoView('')
                setimgView('')
                setOpenViewImage(false);
            }
        } catch {
            Swal.fire({
                title: 'Ocurrio un error al obtener la imagen',
                text: 'Algo salio mal al intentar obtener la imagen del servidor',
                icon: 'error',
            });
            setSorteoView('')
            setimgView('')
            setOpenViewImage(false);
        }
    }
    const handleCloseViewImage = () => {
        setSorteoView('')
        setimgView('')
        setOpenViewImage(false);
    }

    return (
        <Container fluid className="mainSection">
            <div style={{ margin: "40px", }}>
                <Row>
                    <Col>
                        <h1 className="py-4 fw-bold ">Extractos Tuqui</h1>
                    </Col>
                    <Col className="d-flex justify-content-end m-3 ">
                        {AuthenticationHelper.getRol() &&
                            (rolUser === "ADMINISTRADOR" || rolUser === "GERENCIA-QUINIELA" || rolUser ==='OPERADOR-EXTRACTO-TUQUI') ? (
                            <>
                                <Button
                                    className="btn botonCPA m-3"
                                    onClick={() => { setOpenModal(true) }}
                                >
                                    <FaFileMedical className="iconAntD" /> Nuevo Extracto
                                </Button>
                            </>
                        ) : null}
                        {rolUser === "ADMINISTRADOR" || rolUser === "DOCUMENTACION" || rolUser === "CONTADURIA" || rolUser === "GERENCIA-QUINIELA" ?
                            <Button className="btn botonCPA m-3" onClick={handleOpenModalNuevoSorteo}>
                                <FaPlusCircle className="iconAntD" />
                                NUEVO SORTEO
                            </Button>
                            :
                            null
                        }
                    </Col>
                </Row>
                <Card >
                    <Card.Body>
                        <Row className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Form.Group as={Col} md="3">
                                <Form.Label>Fecha:</Form.Label>
                                <Datetime
                                    timeFormat={false}
                                    dateFormat="DD/MM/YYYY"
                                    inputProps={{
                                        readOnly: true,
                                        placeholder: 'Elegir fecha',
                                    }}
                                    ref={datetimeRef}
                                    value={fecha.fechaMuestra}
                                    onChange={handleFechaChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="2" style={{ float: 'right', padding: '15px', marginTop: '15px' }}>
                                <Link className="btn botonCPA me-1" onClick={handleClickClear} style={{ float: 'right' }}>
                                    <FaEraser className="iconAntD" />Limpiar Filtro
                                </Link>
                            </Form.Group>
                        </Row>
                    </Card.Body>
                    <MaterialReactTable
                        className="w-100"
                        columns={columns}
                        data={data}
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: "0",
                                border: "1px dashed #e0e0e0",
                            },
                        }}
                        muiTableBodyProps={{
                            sx: (theme) => ({
                                "& tr:nth-of-type(odd)": {
                                    backgroundColor: darken(
                                        theme.palette.background.default,
                                        0.1
                                    ),
                                },
                                fontFamily: "Roboto, sans-serif", // Configuración de la tipografía para las filas pares
                            }),
                        }}

                        initialState={{
                            columnVisibility: { id: false },
                            showColumnFilters: true,
                        }} //hide firstName column by default
                        editingMode="modal" //default
                        // enableEditing
                        enableRowSelection={false} //enable some features
                        enableColumnOrdering={false}
                        enableHiding={false}
                        enableColumnActions={false}
                        enableSorting={true}
                        displayColumnDefOptions={{ "mrt-row-actions": { size: 10 } }}
                        enableRowActions
                        positionActionsColumn="last"
                        renderRowActions={({ row }) => {
                            return (
                                <IconButton
                                    onClick={() => {
                                        handleViewImage(row.original);
                                    }}
                                >
                                    <Visibility />
                                </IconButton>
                            );
                        }}
                        manualPagination
                        enablePagination={true}
                        localization={MRT_Localization_ES}
                        rowCount={count}
                        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                        // state={{ pagination }} //pass the pagination state to the table
                        enableGlobalFilter={false} //turn off a feature
                        //enableColumnFilters={columnFilters.email}
                        manualFiltering //turn off client-side filtering
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10],
                        }}
                        onColumnFiltersChange={(value) => {
                            setColumnFilters(value);
                        }} //hoist internal columnFilters state to your state
                        // manualSorting  // comentado para habilitar el orden
                        onSortingChange={setSorting}
                        state={{
                            columnFilters,
                            pagination,
                            sorting,
                        }}
                    />
                </Card>
                {
                    rolUser === "OPERADOR-EXTRACTO-TUQUI" ?
                        null
                        :
                        <Row className="text-center">
                            <section className="d-flex justify-content-end my-3">
                                <BtnVolver route="/" />
                            </section>
                        </Row>

                }
            </div>
            <Modal centered show={openModal}>
                <Modal.Header closeButton onClick={handleCloseModal}>
                    <Modal.Title>Nuevo Extracto Tuqui</Modal.Title>
                </Modal.Header>
                <Form onSubmit={subirExtracto}>
                    <Modal.Body>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Sorteo</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                value={sorteo.id}
                                onChange={(event) => {
                                    handleTablaSorteoChange(event.target.value);
                                }}
                            >
                                <option value="" hidden>Elegir Sorteo</option>
                                {
                                    tablaSorteo.length > 0 ?
                                        tablaSorteo.map((sorteo) => (
                                            <option key={sorteo.id} value={sorteo.id}>
                                                Sorteo N° {sorteo.numero}
                                            </option>
                                        ))
                                        : null
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Imagen Extracto</Form.Label>
                            <Form.Control
                                id="archivoExtracto"
                                size="sm"
                                required
                                type="file"
                                accept=".jpg,.jpeg"
                                onChange={handleArchivoPDFChange}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn botonCPA m-3" onClick={handleCloseModal}>
                            Cancelar
                        </Button>
                        <Button className="btn botonCPA m-3" type="submit" >
                            Guardar
                        </Button>
                    </Modal.Footer>

                </Form>
            </Modal>
            <Modal centered show={isLoading}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Subiendo Archivo...</p>
                </Modal.Body>
            </Modal>
            <ModalNuevoSorteo
                onClose={handleCloseModalNuevoSorteo}
                show={showModalNuevoSorteo}
                titulo={tituloModal}
            />
            <Modal centered show={openViewImage} >
                <Modal.Header closeButton onClick={()=>{handleCloseViewImage()}}>
                    <Modal.Title>Extracto Sorteo N° {sorteoView}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={imgView} alt="Extracto" style={{maxWidth:'100%',maxHeight:'100%'}}/>

                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    );
};
export default SubirExtractoTuqui;
